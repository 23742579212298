import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../../components/layout";

import { Header } from "../../components/03_organisms/header";
import { Footer } from "../../components/03_organisms/footer";
import profilePic from "../../images/icon.png";

const PolicyPage = ({ data }) => {
  //  console.log(data)
  const pageTitle = "アプリケーション・プライバシーポリシー";
  return (
    <Layout>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={pageTitle}
        meta={[
          { name: "description", content: data.site.siteMetadata.description },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: pageTitle },
          {
            property: "og:description",
            content: data.site.siteMetadata.description,
          },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + profilePic,
          },
          { "http-equiv": "expires", content: "43200" },
          //{ property: 'og:image', content: mushiIcon },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: "0 auto",
          padding: "0px 0.6rem",
          maxWidth: "1100px",
        }}>
        <div id="flex-root">
          <div
            style={{
              background: "#ffffff",
              padding: "10px 16px",
              boxShadow: "0 2px 4px rgba(0,0,0,.2)",
            }}>
            <h1>アプリケーション・プライバシーポリシー</h1>
            <p>
              本アプリケーションを利用するにあたり、以下のプライバシーポリシーが適用されます。
              お客様は、本アプリケーションを利用することにより、本アプリケーション・ポリシーに同意したものとみなされます。
            </p>
            <p>
              本アプリケーション・ポリシーにおいて、個人情報とは、個人情報保護法により定義された個人情報及び特定の個人情報に結びついて使用される個人に関する属性情報（メールアドレス・利用履歴・利用料金等）を意味するものとします。
            </p>
            <h2>個人情報の管理</h2>
            本アプリではお客さまの個人情報を保持するにあたり、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するために適切な措置を講じ、安全対策を実施し個人情報の管理を行ないます。
            <h2>個人情報の利用目的</h2>
            <p>
              本アプリでは、広告配信ツールとしてAdMob(Google
              Inc.)を使用しており、AdMobがご利用者の情報を自動取得する場合がございます。取得する情報、利用目的、第三者への提供等につきましては、以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。
            </p>
            Google 広告に関するポリシー
            <br />
            <a href="https://policies.google.com/technologies/ads?hl=ja">
              https://policies.google.com/technologies/ads?hl=ja
            </a>
            <h2>利用状況解析ついて</h2>
            <p>
              本アプリでは、今後の開発の参考とするため、アプリの利用状況データを収集するツールとしてFirebase(Google
              Inc.)を使用しており、Firebaseがご利用者の情報を自動取得する場合がございます。取得する情報、利用目的、第三者への提供等につきましては、以下のGoogleプライバシーポリシーのリンクよりご確認ください。
            </p>
            Google プライバシーポリシー
            <br />
            <a href="https://policies.google.com/privacy?hl=ja">
              https://policies.google.com/privacy?hl=ja
            </a>
            <h2>個人情報の第三者への開示・提供の禁止</h2>
            <p>
              お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。
            </p>
            <li>お客さまの同意がある場合</li>
            <li>法令に基づき開示することが必要である場合</li>
            <li>司法または行政機関から開示を求められた場合</li>
            <h2>個人情報の安全対策</h2>
            個人情報の正確性及び安全性確保のために、十分なセキュリティに万全の対策を講じ、万が一、問題が発生した場合は、速やかに適当な是正対策をします。
            <h2>法令、規範の遵守と見直し</h2>
            保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
            <br />
            <br />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 20
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
          }
          fields {
            slug
          }
          excerpt(truncate: true)
        }
      }
    }
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
    tags: allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

export default PolicyPage;
